import React from "react";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";

function GlassCard(props) {
  const mesh = React.useRef();

  const [hovered, setHover] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [mouse, setMouse] = React.useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    if(e.type === "mousemove") {
      setMouse({ x: e.clientX, y: e.clientY });
    } else {
      let x = e.touches[0].clientX;
      let y = e.touches[0].clientY;
      setMouse({ x: x, y: y });
    }
  };

  React.useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleMouseMove);
    };
  });

  useFrame((state, delta) => {
    let xRotation =
      (((mouse.y - window.innerHeight / 2) / window.innerHeight) * Math.PI) / 2;
    let yRotation =
      (((mouse.x - window.innerWidth / 2) / window.innerWidth) * Math.PI) / 2;
    mesh.current.rotation.x = xRotation * 0.1;
    mesh.current.rotation.y = yRotation * 0.1;
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={0.75}
      onPointerDown={(event) => setActive(!active)}
      onPointerUp={(event) => setActive(false)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <boxGeometry
        args={[1.5, 1, 0.1]}
        attach="geometry"
        castShadow={true}
        receiveShadow={true}
      />
      <meshStandardMaterial
        color={"#dbedff"}
        transparent={true}
        opacity={0.1}
        roughness={0.7}
        metalness={0.7}
      />
      <Html
        style={{
          transition: "all 0.2s",
          transform: `scale(0.5)`,
          textAlign: "left",
          fontFamily: "monospace",
          fontWeight: "bold",
          color: "white",
          textShadow: "0 0 20px #8993ff",
          fontSize: "1.5rem",
          height: "500px",
            width: "500px",
          maxWidth: "600px",
          maxHeight: "600px",
          overflowY: "scroll",
          zIndex: "1",
          paddingLeft: "10px",
        }}
        distanceFactor={1.0}
        transform
        position={[0, 0, 0.51]}
      >
        {props.html}
      </Html>
    </mesh>
  );
}

export default GlassCard;
