import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-text">
        Made by Quinten Wiggill with <a href="https://github.com/pmndrs/react-three-fiber" target="_blank">React Three Fiber</a>
      </div>
      {/*<div className="footer-text"><a href="/">Fork on github</a></div>*/}
    </div>
  );
}
