import React from "react";
import { useFrame } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
import * as random from "maath/random/dist/maath-random.esm";

function Planet(props) {
  const ref = React.useRef();
  const [sphere] = React.useState(() =>
    random.inSphere(new Float32Array(props.count || 50), { radius: props.radius || 6 }),
  );

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 10;
    ref.current.rotation.y -= delta / 15;
    ref.current.rotation.z -= delta / 20;
  });

  return (
    <group
      rotation={[0, 0, Math.PI / 4]}
    >
      <Points
        ref={ref}
        positions={sphere}
        stride={3}
        frustumCulled={false}
        {...props}
      >
        <PointMaterial
          transparent
          color={props.color || "white"}
          size={props.size || 0.05}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}

export default Planet;  
