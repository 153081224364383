import React from "react";
import { Canvas } from "@react-three/fiber";
import GlassCard from "../components/GlassCard";
import AppBar from "../components/AppBar";
import NavMenu from "../components/NavMenu";
import Footer from "../components/Footer";

const AboutContainer = () => {
  return (
    <div className="box-text">
      <div className="box-title">
        <h1 className="type-effect">ABOUT</h1>
      </div>
      <p>
        I'm a sofware developer based in New Zealand with a passion for learning and building
        solutions. I have a business oriented, results driven mindset and therefore
        always strive to use my skills to deliver value to the business and the end user.
      </p>
      <p>Professional interests:</p>
      <ul>
        <li> Web and Mobile Development </li>
        <li> ML/AI</li>
        <li> Systems Design </li>
        <li> Quantum Computing </li>
        <li> Graphics Programming</li>
      </ul>
      <p>
        I am currently working at Sandfield Associates as a Software Developer.
      </p>
    </div>
  );
};

const ExperienceContainer = () => {
  const experience = [
    {
      title: "Sandfield Associates",
      role: "Software Developer",
      date: "Jul 2024 - Present",
    },
    {
      title: "GoodX Software/Quant Engineering",
      role: "Software Developer",
      date: "Oct 2022 - Jun 2024",
    },
    {
      title: "Drivatic",
      role: "Software Development Manager",
      date: "Feb 2022 - Sep 2022",
    },
    {
      title: "Drivatic",
      role: "Full Stack Developer",
      date: "Oct 2021 - Jan 2022",
    },
    {
      title: "Symbit",
      role: "Freelance Full Stack Developer",
      date: "May 2021 - Oct 2021",
    },
    {
      title: "Osidon",
      role: "Junior Accountant",
      date: "Mar 2021 - May 2021",
    },
    {
      title: "Accsecure",
      role: "Accounting Clerk",
      date: "Feb 2020 - Mar 2021",
    },
    {
      title: "Brinant Group",
      role: "Debtors Clerk",
      date: "Mar 2019 - Feb 2020",
    }
  ]
  return (
    <div className="box-text">
      <div className="box-title">
        <h1 className="type-effect">EXPERIENCE</h1>
      </div>
      {experience.map((item, index) => (
        <div style={{ marginTop: 2, borderTop: index > 0 ? "dotted 1px" : "" }}>
          <h2>{item.title}</h2>
          <h6>{item.date}</h6>
          <p>{item.role}</p>
        </div>
      ))}
    </div>
  );
};

const SkillsContainer = () => {
  return (
    <div className="box-text">
      <div className="box-title">
        <h1 className="type-effect">SKILLS</h1>
      </div>
      <h2>Languages</h2>
      <ul>
        <li>JavaScript/TypeScript</li>
        <li>C#</li>
        <li>SQL</li>
        <li>Rust</li>
        <li>Python</li>
      </ul>
      <h2>Frameworks</h2>
      <ul>
        <li>React</li>
        <li>React Native</li>
        <li>NextJS</li>
        <li>.NET</li>
        <li>Django</li>
      </ul>
      <h2>Databases</h2>
      <ul>
        <li>PostgreSQL</li>
        <li>SQL Server</li>
      </ul>
      <h2>Tools</h2>
      <ul>
        <li>Docker</li>
        <li>Azure</li>
        <li>AWS</li>
      </ul>
    </div>
  );
};

const cardHtml = {
  about: <AboutContainer />,
  experience: <ExperienceContainer />,
  skills: <SkillsContainer />,
};

export default function MainScreen() {
  const [activeCard, setActiveCard] = React.useState("about");
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 430;

  return (
    <div id="main-container">
      <AppBar />
      <NavMenu setActiveCard={setActiveCard} activeCard={activeCard} />
      <Canvas camera={{ position: [0, 0, 1] }} style={{ zIndex: -1 }}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        {!isMobile && <GlassCard html={cardHtml[activeCard]} />}
      </Canvas>
      {isMobile && <div className="mobile-card">{cardHtml[activeCard]}</div>}
      <Footer />
    </div>
  );
}
