import "./App.css";
import React from "react";
import MainScreen from "./screens/MainScreen";
import Background from "./components/Background";

function App() {
  return (
    <>
      <Background />
      <MainScreen />
    </>
  );
}

export default App;
