import React from "react";

export default function NavMenu(props) {
  const handleSetActiveCard = (card) => {
    props.setActiveCard(card);
  };

  return (
    <div className="nav-menu">
      <div className="nav-menu-item">
        <a
          style={{ color: props.activeCard === "about" ? "white" : "" }}
          onClick={() => handleSetActiveCard("about")}
        >
          About
        </a>
      </div>
      <div className="nav-menu-item">
        <a
          style={{ color: props.activeCard === "experience" ? "white" : "" }}
          onClick={() => handleSetActiveCard("experience")}
        >
          Experience
        </a>
      </div>
      <div className="nav-menu-item">
        <a
          style={{ color: props.activeCard === "skills" ? "white" : "" }}
          onClick={() => handleSetActiveCard("skills")}
        >
          Skills
        </a>
      </div>
    </div>
  );
}
